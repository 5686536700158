import _keystore from "./keystore";
import _in_memory_key_store from "./in_memory_key_store";
import _browser_local_storage_key_store from "./browser_local_storage_key_store";
import _merge_key_store from "./merge_key_store";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MergeKeyStore = exports.BrowserLocalStorageKeyStore = exports.InMemoryKeyStore = exports.KeyStore = void 0;
/** @hidden @module */
const keystore_1 = _keystore;
Object.defineProperty(exports, "KeyStore", {
  enumerable: true,
  get: function () {
    return keystore_1.KeyStore;
  }
});
const in_memory_key_store_1 = _in_memory_key_store;
Object.defineProperty(exports, "InMemoryKeyStore", {
  enumerable: true,
  get: function () {
    return in_memory_key_store_1.InMemoryKeyStore;
  }
});
const browser_local_storage_key_store_1 = _browser_local_storage_key_store;
Object.defineProperty(exports, "BrowserLocalStorageKeyStore", {
  enumerable: true,
  get: function () {
    return browser_local_storage_key_store_1.BrowserLocalStorageKeyStore;
  }
});
const merge_key_store_1 = _merge_key_store;
Object.defineProperty(exports, "MergeKeyStore", {
  enumerable: true,
  get: function () {
    return merge_key_store_1.MergeKeyStore;
  }
});
export default exports;