import _browserIndex from "./key_stores/browser-index";
import _commonIndex from "./common-index";
import _browserConnect from "./browser-connect";
import * as _errorPolyfill2 from "error-polyfill";
var _errorPolyfill = "default" in _errorPolyfill2 ? _errorPolyfill2.default : _errorPolyfill2;
var exports = {};
var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = exports && exports.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = exports && exports.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
var __exportStar = exports && exports.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.keyStores = void 0;
/** @hidden @module */
exports.keyStores = __importStar(_browserIndex);
__exportStar(_commonIndex, exports);
__exportStar(_browserConnect, exports);
_errorPolyfill;
export default exports;
export const __esModule = exports.__esModule,
  keyStores = exports.keyStores,
  WalletConnection = exports.WalletConnection,
  ConnectedWalletAccount = exports.ConnectedWalletAccount,
  Near = exports.Near,
  KeyPair = exports.KeyPair,
  Signer = exports.Signer,
  InMemorySigner = exports.InMemorySigner,
  Contract = exports.Contract,
  Connection = exports.Connection,
  Account = exports.Account,
  multisig = exports.multisig,
  validators = exports.validators,
  transactions = exports.transactions,
  utils = exports.utils,
  providers = exports.providers,
  accountCreator = exports.accountCreator,
  connect = exports.connect;